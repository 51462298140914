.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.cr-page__title {
  margin-bottom: 5px !important;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  margin-bottom: 5px;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}